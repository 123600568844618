import { endpoints } from "../config";
import axios from "axios";

const staffRequestService = {
  fetchStaffRequest,
};

function fetchStaffRequest(id) {
  return axios
    .get(`${endpoints.staffRequests}${id}/`)
    .then((result) => result)
    .catch((error) => {
      if (error.response.status === 404) console.log("404");
    });
}

export default staffRequestService;
