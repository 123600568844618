import React from "react";
import { Avatar, Typography, Skeleton } from "antd";
import colors from "../../styles/colors";

const { Text, Title } = Typography;

const AppTitleSection = ({ client, position, title }) => {
  if (!title) return <Skeleton avatar paragraph={{ rows: 2 }} />;
  return (
    <div>
      <div style={{ padding: "0px 20px 0px 20px" }}>
        <Avatar size={96} src={client.logo}></Avatar>
        <div>
          <Text strong style={{ color: colors.blue }}>
            {position.name}
          </Text>
        </div>

        <Title>{title}</Title>
        <Text type="secondary">{client.name}</Text>
      </div>
    </div>
  );
};

export default AppTitleSection;
