import React, { Component } from "react";
import { Router, navigate } from "@reach/router";
import branch from "branch-sdk";

import routes from "../routes";
import JobDetail from "../components/jobs/JobDetail";
import {
  BRANCH_KEY,
  IOS_BRANCH_DEFAULT_REDIRECT,
  ANDROID_BRANCH_DEFAULT_REDIRECT,
  DESKTOP_BRANCH_DEFAULT_REDIRECT,
} from "../config";

class App extends Component {
  componentDidMount() {
    if (window.zE) {
      window.zE("webWidget", "hide");
    }
    if (typeof window !== "undefined") {
      branch.init(BRANCH_KEY, (err, data) => {
        if (err) {
          console.warn(`Branch failed to resolve link: ${err}`);
        }
        if (data) {
          console.log(data);
          branch.link(
            {
              data: {
                ...data.data_parsed,
                $ios_url: IOS_BRANCH_DEFAULT_REDIRECT,
                $android_url: ANDROID_BRANCH_DEFAULT_REDIRECT,
                $desktop_url: DESKTOP_BRANCH_DEFAULT_REDIRECT,
              },
            },
            function (err, link) {
              if (link) localStorage.setItem("branchLink", link);
              if (data.data_parsed && data.data_parsed.job_id) {
                const lang = data.data_parsed.lang
                  ? `?lang=${data.data_parsed.lang}`
                  : "";
                navigate(
                  `${routes.jobDetail.replace(
                    ":id",
                    data.data_parsed.job_id
                  )}${lang}`
                );
              }

              branch.track("pageview");
            }
          );
        }
      });
    }
  }

  render() {
    console.log("> App props:", this.props);
    return (
      <Router>
        <JobDetail path="app/jobs/:id" />
      </Router>
    );
  }
}

export default App;
