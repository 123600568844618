import React from "react";
import { Typography, Button } from "antd";
import colors from "../../styles/colors";
import { FormattedMessage } from "react-intl";

const GetAppSection = ({ branchLink }) => {
  return (
    <div
      style={{
        background: colors.aliceBlue,
        textAlign: "center",
      }}
    >
      <div style={{ padding: "30px" }}>
        <Typography.Title level={2}>
          <FormattedMessage id={"almost_there"} />
        </Typography.Title>
        <Typography.Paragraph>
          <FormattedMessage id={"sign_up_workmate_app"} />
        </Typography.Paragraph>
        <Button
          href={branchLink}
          target={"_blank"}
          style={{
            background: colors.green,
            width: "100%",
          }}
          // onClick={() => {
          //   window.analytics.track("Clicked Install Workmate App button", {
          //     location: "Job Detail"
          //   });
          // }}
        >
          <Typography.Text
            strong
            style={{
              color: "white",
            }}
          >
            <FormattedMessage id={"install_workmate_app"} />
          </Typography.Text>
        </Button>
      </div>
    </div>
  );
};

export default GetAppSection;
