import { navigate } from "gatsby";
import React, { Component } from "react";
import { Skeleton, Divider, Row, Col } from "antd";
import staffRequestService from "../../services/staffRequests";
import AppTitleSection from "./AppTitleSection";
import WebTitleSection from "./WebTitleSection";
import InfoSection from "./InfoSection";
import DescriptionSection from "./DescriptionSection";
import GetAppSection from "./GetAppSection";
import MapSection from "./MapSection";
import PublicLayout from "../shared/PublicLayout";
import Seo from "../shared/seo";

class JobDetail extends Component {
  state = { job: undefined, branchParameters: undefined, loading: false };

  componentDidMount() {
    const jobId = this.props.id;
    // window.analytics.page("Job Detail");

    if (window.zE) {
      window.zE("webWidget", "hide");
    }

    this.getJob(jobId);
  }

  getJob = async (jobId) => {
    this.setState({ loading: true });
    await staffRequestService
      .fetchStaffRequest(jobId)
      .then((result) => this.setState({ job: result.data, loading: false }))
      .catch((error) => navigate(`/404`));
  };

  render() {
    const { location, locale } = this.props;
    if (!this.state.job) return <Skeleton />;
    const { client, position, title, description } = this.state.job;
    return (
      <PublicLayout location={location} locale={locale}>
        <Seo
          title={`${title} at ${client.name} | Workmate`}
          description={description}
          image={client.logo}
        />
        <Row type="flex" justify="center">
          <Col xs={24} style={{ marginBottom: 24 }}>
            <MapSection
              latitude={this.state.job.location.address.latitude}
              longitude={this.state.job.location.address.longitude}
            />
          </Col>
        </Row>
        <Row>
          <Col md={15} xs={24}>
            <Row type="flex">
              <Col xs={0} md={24} style={{ padding: "0 20px" }}>
                <WebTitleSection
                  client={client}
                  position={position}
                  title={title}
                />
              </Col>
              <Col xs={24} md={0}>
                <AppTitleSection
                  address={this.state.job.location.address}
                  client={client}
                  position={position}
                  title={title}
                />
              </Col>
              <Divider />
            </Row>
            <Row type="flex" style={{ padding: "0 20px" }}>
              <Col xs={0} md={24}>
                <DescriptionSection description={description} />
              </Col>
              <Col xs={24} md={0}>
                <InfoSection job={this.state.job} />
              </Col>
            </Row>
            <Divider />
          </Col>

          <Col md={{ span: 8, offset: 1 }} xs={{ span: 24, offset: 0 }}>
            <Row type="flex" style={{ padding: "0 20px" }}>
              <Col xs={0} md={24}>
                <InfoSection job={this.state.job} />
              </Col>
              <Col xs={24} md={0}>
                <DescriptionSection description={description} />
              </Col>
            </Row>

            <Row style={{ paddingTop: "30px" }}>
              <GetAppSection branchLink={localStorage.getItem("branchLink")} />
            </Row>
          </Col>
        </Row>
      </PublicLayout>
    );
  }
}

export default JobDetail;
