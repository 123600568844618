import _ from "lodash";

function humanizeText(string) {
  if (!string || string.length === 0) {
    return "";
  }
  return _.startCase(string.replace("_", " "));
}

const textUtils = {
  humanizeText,
};

export default textUtils;
