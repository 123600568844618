import React from "react";
import { Typography, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";

const DescriptionSection = ({ description }) => {
  if (!description) return <Skeleton />;
  return (
    <div>
      <Typography.Title level={2}>
        <FormattedMessage id={"job_description"} />
      </Typography.Title>
      <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
        {description}
      </Typography.Paragraph>
    </div>
  );
};

export default DescriptionSection;
