import React from "react";
import { Avatar, Typography, Skeleton, Row, Col } from "antd";
import colors from "../../styles/colors";

const { Text, Title } = Typography;

const WebTitleSection = ({ client, position, title }) => {
  if (!title) return <Skeleton avatar paragraph={{ rows: 2 }} />;

  return (
    <Row type="flex">
      <Col span={5}>
        <Avatar size={96} src={client.logo}></Avatar>
      </Col>
      <Col span={19}>
        <Text strong style={{ color: colors.blue }}>
          {position.name}
        </Text>
        <Title style={{ marginTop: "0px" }}>{title}</Title>
        <Text type="secondary">{client.name}</Text>
      </Col>
    </Row>
  );
};

export default WebTitleSection;
