import React from "react";
import { Menu, Dropdown, Icon, Button } from "antd";
import locales from "../../locales";
import colors from "../../styles/colors";
import { getLocaleByKey } from "../../utilities/i18n";

const LanguageDropdown = ({ language, onClick }) => {
  const menu = (
    <Menu>
      {locales.map(locale => (
        <Menu.Item key={locale.key} onClick={({ key }) => onClick(key)}>
          {locale.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button type="link" style={{ color: colors.darkGrey }}>
        <Icon type="global" /> {getLocaleByKey(language).name}{" "}
        <Icon type="down" />
      </Button>
    </Dropdown>
  );
};

export default LanguageDropdown;
