import React, { Component } from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import { Row, Card } from "antd";
import queryString from "query-string";
import "antd/dist/antd.css";
import enData from "react-intl/locale-data/en";
import idData from "react-intl/locale-data/id";
import thData from "react-intl/locale-data/th";
import en from "../../i18n/en.json";
import id from "../../i18n/id.json";
import th from "../../i18n/th.json";
import brandLogo from "../../assets/images/brand-logo.svg";
import LanguageDropdown from "./LanguageDropdown";
import { getLocaleByKey } from "../../utilities/i18n.js";
import { jobsPublicWebsiteLinks } from "../../config";

const messages = {
  "en-gb": en,
  id: id,
  th: th,
};

addLocaleData([...enData, ...idData, ...thData]);

class PublicLayout extends Component {
  state = { language: "en-gb" };

  componentDidMount() {
    const { lang } = queryString.parse(this.props.location.search);
    if (lang && getLocaleByKey(lang)) {
      this.setState({ language: lang });
    }
  }

  handleLanguageChange = (language) => {
    if (language) this.setState({ language });
  };

  render() {
    const { language } = this.state;
    return (
      <IntlProvider locale={language} messages={messages[language]}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ paddingBottom: "50px", maxWidth: "1168px", margin: "auto" }}
        >
          <Card bordered={false} style={{ width: "100%" }}>
            <a href={jobsPublicWebsiteLinks[language]} rel="noopener">
              <img
                loading={"lazy"}
                src={brandLogo}
                alt="Logo"
                style={{
                  width: "130px",
                  paddingRight: "30px",
                }}
              />
            </a>
            <LanguageDropdown
              language={language}
              onClick={this.handleLanguageChange}
            />
          </Card>
          <div style={{ width: "100%" }}>{this.props.children}</div>
        </Row>
      </IntlProvider>
    );
  }
}

export default PublicLayout;
