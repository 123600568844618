import React from "react";
import numeral from "numeral";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Typography, Skeleton, Icon, Row, Col } from "antd";
import colors from "../../styles/colors";
import textUtils from "../utilities/textUtils";

const { Text } = Typography;

const getEffectiveWageAmount = (job) => {
  const baseWage = job.wage_amount ? parseFloat(job.wage_amount) : 0;
  const transportAllowance = job.transport_allowance
    ? parseFloat(job.transport_allowance)
    : 0;
  const mealAllowance = job.meal_allowance ? parseFloat(job.meal_allowance) : 0;
  const otherAllowance = job.other_allowance
    ? parseFloat(job.other_allowance)
    : 0;

  return baseWage + transportAllowance + mealAllowance + otherAllowance;
};

const InfoSection = ({ job }) => {
  if (!job) return <Skeleton />;
  return (
    <div>
      <Row type="flex" style={{ paddingBottom: "10px" }} gutter={16}>
        <Col span={2}>
          <Icon type="pay-circle" style={{ color: colors.darkGrey }} />
        </Col>
        <Col span={20}>
          <Text strong>
            {numeral(getEffectiveWageAmount(job)).format("0,0.00")}{" "}
            <FormattedMessage id={textUtils.humanizeText(job.wage_type)} />
          </Text>
        </Col>
      </Row>
      <Row type="flex" style={{ paddingBottom: "10px" }}>
        <Col span={2}>
          <Icon type="calendar" style={{ color: colors.darkGrey }} />
        </Col>
        <Col span={20}>
          <Text strong>
            {moment(job.start_time).format("DD MMM YYYY")} -{" "}
            {moment(job.end_time).format("DD MMM YYYY")}
          </Text>
        </Col>
      </Row>
      <Row type="flex" style={{ paddingBottom: "10px" }} gutter={16}>
        <Col span={2}>
          <Icon type="environment" style={{ color: colors.darkGrey }} />
        </Col>
        <Col span={20}>
          <Text strong>
            <Text type="secondary">
              {job.location.address.street_1} {job.location.address.street_2}{" "}
              {job.location.address.area.name}{" "}
              {job.location.address.area.city.name} {job.location.address.zip}
            </Text>
            <br />
            <a
              style={{ color: colors.green, fontWeight: "bold" }}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${job.location.address.latitude},${job.location.address.longitude}`}
            >
              <FormattedMessage id={"open_in_maps"} />
            </a>
          </Text>
        </Col>
      </Row>
    </div>
  );
};

export default InfoSection;
