import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

class MapSection extends React.Component {
  state = {
    width: 0,
    loading: true,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleBrowserSizeUpdate);
    await this.handleBrowserSizeUpdate();
    this.setState({ loading: false });
  }

  handleBrowserSizeUpdate = () => {
    const newWidth = window.innerWidth;
    this.setState({ width: newWidth });
  };

  getImageHeight = () => {
    return this.state.width > 768 ? 360 : 256;
  };

  getMapZoom = () => {
    return this.state.width > 768 ? 15 : 16;
  };

  render() {
    const { latitude, longitude } = this.props;
    const position = [latitude, longitude];

    if (typeof window !== "undefined" && !this.state.loading) {
      return (
        <MapContainer
          style={{ height: this.getImageHeight() }}
          center={position}
          zoom={this.getMapZoom()}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} />
        </MapContainer>
      );
    }
    return null;
  }
}

export default MapSection;
